<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Перевозчик">
          <b-form-input v-model="form.carrierName" placeholder="Перевозчик" :state="formErrors.carrierName"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import CARRIER from "@/forms/CARRIER";

const CARRIER_INIT_FORM = () => ({
  carrierName: '',
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => CARRIER_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, CARRIER_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, CARRIER)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  }
}
</script>

<style scoped>

</style>