<template>
  <div>
    <b-col cols="12">
      <h2>Перевозчики</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
        <b-button variant="primary" @click="$refs.createModal.show()">Добавить перевозчика</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="carriers" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon class="action-icon" icon="trash-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Удалить"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Create from './Carriers/Create'
import Update from './Carriers/Update'

export default {
  name: "Carriers",
  data() {
    return {
      fields: [
        {
          key: 'carrierName',
          label: 'Название'
        },
        {
          key: 'inn',
          label: 'ИНН'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Carriers.loading,
      carriers: state => state.Carriers.items
   })
  },
  mounted() {
    this.$store.dispatch('Carriers/load', { page: 1 })
  },
  methods: {
    remove(id) {
      console.log(id)
      if (!id)
        return

      if (!confirm('Вы действительно хотите удалить перевозчика?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Carriers/remove', id)
          .then(() => {
            this.$bvToast.toast('Перевозчик был успешно удален.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    update(form) {
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>